<template lang="pug">
  .shop-gemstones(:class="{'shop-gemstones_style-padding': viewButton}")
    .shop-gemstones__content.container.is-max-fullhd
      .shop-gemstones__wrapper
        h2.shop-gemstones__title {{$t('homePage.shopGemstones.title')}}
        .shop-gemstones__subtitle {{$t('homePage.shopGemstones.subtitle')}}
    .shop-gemstones__swiper-slider
      .swiper-container.lazy(data-lazy-function="loaded", ref="shop", v-observe-visibility="{callback: initSwiper, once: true}")
        .swiper-wrapper
          .swiper-slide.is-flex( v-for="slide in slidesStones", :key="slide.id")
            v-link(:to="slide.route")
              .swiper-slide__image
                v-image(:src="slide.src", :alt="slide.name")
              .swiper-slide__name {{ slide.name }}
        .swiper-button-next.is-hidden-touch.swiper-no-swiping(ref="next")
          icon(iconName="arrowRight", icon-color="#000000")
        .swiper-button-prev.is-hidden-touch.swiper-no-swiping(ref="prev")
          icon(iconName="arrowRight", icon-color="#000000")
    .shop-gemstones__wrapper-button.is-flex(v-if="viewButton")
      v-link.button.primary-color.light.is-uppercase(to="natural-gemstones") {{ $t(`homePage.shopGemstones.link.${typeItem}.name`) }}

</template>

<script>
import { guid } from '~~/utils/utils'
import { stones } from '~~/utils/config/gemstoneConfig'

export default {
  name: 'ShopGemstonesComponent',

  props: {
    typeItem: {
      type: String,
      default: 'stone'
    },

    viewButton: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    slidesStones() {
      return stones
        .filter((stone) => stone.itemsCount.stone)
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((stone) => ({
          ...stone,
          id: guid(),
          src: `common/shopType/${this.typeItem}/${stone.kebabCase}@2x.png`,
          route: {
            name: 'dynamicListing',
            params: { dynamicListing: stone.kebabCase }
          }
        }))
    },

    sliderParams() {
      return {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesOffsetBefore: -15,
        centeredSlides: false,
        longSwipesRatio: 0.3,
        allowTouchMove: true,
        slideToClickedSlide: false,
        slidesPerGroup: 1,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          768: {
            spaceBetween: 20,
            centeredSlides: true
          }
        },
        on: {
          init: function () {
            if (window.lazyUpdate) window.lazyUpdate()
          }
        }
      }
    }
  },

  methods: {
    async initSwiper(isVisible) {
      if (isVisible) {
        this.swiper = await this.$swiper.insert(
          this.$refs.shop,
          this.sliderParams
        )
      }
    }
  }
}
</script>

<style scoped lang="sass">
.shop-gemstones
  &__content
    padding-top: 80px
    +touch
      padding-top: 60px
    +mobile
      padding-top: 68px
  &__title
    font-family: $lora
    font-size: 36px
    line-height: 42px
    font-style: italic
    text-align: center
    +touch
      font-size: 32px
      line-height: 42px
  &__subtitle
    font-family: $lato
    font-size: 16px
    line-height: 24px
    text-align: center
    padding: 40px 0 60px 0
    +touch
      padding: 24px 0 31px 0
    +mobile
      padding: 32px 0
  &__swiper-slider
    max-width: 1920px
    margin: 0 auto
    padding-bottom: 130px
    +touch
      padding-bottom: 103px
    +mobile
      padding-bottom: 68px

  &__wrapper-button
    justify-content: center
    & .button
      max-width: 242px
      width: 100%
      +mobile
        max-width: 335px
  &_style-padding
    .shop-gemstones__swiper-slider
      padding-bottom: 0
    .shop-gemstones__wrapper-button
      padding: 80px 0
      +touch
        padding: 60px 0
      +mobile
        padding: 47px 0



.swiper
  &-slide
    flex-direction: column
    width: 178px!important
    cursor: pointer
    +mobile
      width: 100px!important
    ::v-deep
      img
        max-width: 178px
        aspect-ratio: 1 / 1
        +mobile
          width: 100px
    &__name
      text-align: center
      font-size: 18px
  &-button-next
    right: 50px
    &::after
      content: none
  &-button-prev
    left: 50px
    &::after
      content: none
    svg
      transform: rotate(180deg)
.swiper-button-next,
.swiper-button-prev
  width: 60px
  height: 60px
  display: flex
  align-items: center
  justify-content: center
  background: #fff
  box-shadow: 0 1px 8px #00000029
  border-radius: 50%
  top: 50%
  transform: translateY(-50%)
  svg
    width: 30px
    height: 30px
</style>
